import { getElemSizeULByRef, useItemSizeUL } from "@/hooks/useItemSizeUL";
import { cn } from "@/lib/utils";
import { forwardRef, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Button, ButtonProps } from "./Button";
import { getObjectKeysValue } from "@/utils/objectManipulation";
import { removeRegexFromString } from "@/utils/stringManipulation";
import camelOrSnakeToTitleCase from "@/utils/camelOrSnakeToTitleCase";

interface CuteWordOrbProps extends React.HTMLAttributes<HTMLButtonElement> {
  text?: string;
  maxChars?: number;
  maxWidthPX?: number;
  showFull?: boolean;
  selected?: boolean;
  iconsMap?: Record<string, any>;
  parseText?: (text: string) => string;
  allowWrap?: boolean;
  allowBannerScroll?: boolean;
  className?: string;
  buttonSize?: ButtonProps["size"];
  buttonVariant?: ButtonProps["variant"];
  onClick?: any;
  children?: React.ReactNode;
  innerClassName?: string;
}

const CuteWordOrb = forwardRef<HTMLDivElement, CuteWordOrbProps>(
  (
    {
      id,
      text,
      maxChars = 10,
      maxWidthPX = 95,
      showFull = false,
      selected = false,
      allowWrap = false,
      allowBannerScroll = false,
      className,
      buttonSize = "blank",
      buttonVariant = "blank",
      parseText,
      iconsMap,
      children,
      innerClassName,
      ...props
    },
    ref
  ) => {
    const [isOverflowing, setIsOverflowing] = useState(false);
    const [bannerScroll, setBannerScroll] = useState(false);
    const orbRef = useRef(null);
    const wordRef = useRef(null);

    useLayoutEffect(() => {
      if (bannerScroll) return;
      const { upperLeftPosition: XY, width, height } = getElemSizeULByRef(orbRef);
      const { upperLeftPosition: wordXY, width: wordWidth, height: wordHeight } = getElemSizeULByRef(wordRef);
      //console.log("Words positions");
      console.log(XY, wordXY);
      //if ((maxWidthPX && (wordXY.x + wordWidth + 5 >= XY.x + width)) || (!showFull && (wordWidth + 10 >= maxWidthPX))) {
      if (wordWidth >= maxWidthPX || (allowBannerScroll && wordXY.x + wordWidth >= XY.x + width)) {
        setIsOverflowing(true);
      } else {
        setIsOverflowing(false);
      }
    }, [text, children]);

    return (
      <Button
        id={id}
        ref={orbRef}
        onMouseEnter={() => {
          if (isOverflowing && allowBannerScroll) setBannerScroll(true);
        }}
        onMouseLeave={() => {
          if (isOverflowing && allowBannerScroll) setBannerScroll(false);
        }}
        variant={buttonVariant}
        size={buttonSize}
        className={cn(
          "border-1 flex min-h-fit overflow-x-hidden rounded-md border border-input py-0.5 text-sm font-normal",
          {
            "-pr-0.5": isOverflowing,
            "border-purple bg-faintPurple": selected,
            "w-full justify-start": !isOverflowing,
          },
          className,
          ""
        )}
        innerClassName={innerClassName}
        {...props}
      >
        {children ?? (
          <>
            {text && iconsMap && iconsMap.default && (
              <div className="z-10 h-full rounded-md bg-background-light pl-1.5 pr-0.5 text-secondary-dark">
                {(() => {
                  //TODO is this function necessary? what is it doing? Find the appropriate icon component
                  let IconComponent = getObjectKeysValue(iconsMap, text, true, true);
                  return <IconComponent sx={{ fontSize: 15 }} />;
                })()}
              </div>
            )}
            <div
              //the sliding container
              className={cn("flex h-min w-fit gap-x-0.5 pl-1", {
                "duration-[9000ms] animate-slideLeftSlow transition-all": bannerScroll,
                "pr-0": isOverflowing,
                "pr-1": !isOverflowing,
              })}
            >
              <div
                //The original text
                ref={wordRef}
                style={{
                  maxWidth: showFull || bannerScroll ? undefined : `${maxWidthPX}px`,
                  overflow: bannerScroll ? "visible" : "hidden",
                  textOverflow: bannerScroll ? "" : "ellipsis",
                  whiteSpace: allowWrap ? "normal" : "nowrap",
                  textAlign: "start",
                }}
                className={cn("w-full justify-start", { "": bannerScroll })}
              >
                {text && parseText ? parseText(text) : text}
              </div>
              {allowBannerScroll && bannerScroll && (
                //duplicate text for banner scroll
                <>
                  <div className="mx-2 w-[1px] bg-secondary-dark"></div>
                  <div
                    style={{
                      whiteSpace: allowWrap ? "normal" : "nowrap",
                    }}
                  >
                    {text && parseText ? parseText(text) : text}
                  </div>
                  <div className="mx-2 w-[1px] bg-secondary-dark"></div>
                </>
              )}
            </div>
          </>
        )}
      </Button>
    );
  }
);
CuteWordOrb.displayName = "CuteWordOrb";
export { CuteWordOrb };
